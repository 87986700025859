import React, { useEffect, useState } from 'react';
import './LoadingScreen.css';

function LoadingScreen() {
  const [expand, setExpand] = useState(false);
  const [isVisible, setIsVisible] = useState(true);  

  useEffect(() => {
    const timer = setTimeout(() => {
      setExpand(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleAnimationEnd = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null; 

  return (
    <div className={`loading-screen ${expand ? 'expand' : ''}`} onAnimationEnd={handleAnimationEnd} >
      <div className="spinner"> 
        <div className="circle circle1"></div>
        <div className="circle circle2"></div>
      </div>
    </div>
);
}

export default LoadingScreen;
